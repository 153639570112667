/* Global Styles */
body {
  font-family: "Noto Kufi Arabic", sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: #101010; /* Overall dark background for the page */
}

html{
  scroll-behavior: smooth;

}

h2, h4 {
  font-weight: bold;
}

a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Hero Section */
.hero-section {
  position: relative;
  height: 100vh;
  background: url('./xenon-cover-1.jpg') no-repeat center center/cover;
  background-color: #1a1a1a; /* Dark background for hero */
  justify-content: center;
  align-items: center;
}

.hero-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.hero-overlay h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.hero-overlay p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.cta-button {
  padding: 10px 30px;
  background-color: transparent;
  color: white;
  font-size: 1.2em;
  border-radius: 5px;
  transition: background-color 0.3s;
  border: 1px solid white;
  font-weight: 600;
}

.cta-button:hover {
  background-color: #0f4f67;
  color: white;
}

/* Features Section */
.features {
  background-color: #1e2a30; /* A dark shade of blue-gray */
  padding: 50px 20px;
  text-align: center;
  color: white;
}

.features-heading {
  font-size: 2.5em;
  margin-bottom: 30px;
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.feature-card {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-image {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.check-icon {
  color: #28a745;
  font-size: 1.5em;
  margin-top: 20px;
}

/* Warranty Section */
.warranty {
  background-color: #232a34; /* Dark slate gray */
  padding: 50px 20px;
  color: white;
}
.warranty-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.warranty-image img {
  max-width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.warranty-text{
  text-align: end;
  margin-right: 2%;
}

.warranty-text h2 {
  font-size: 2.5em;
}

.warranty-text p {
  font-size: 1.2em;
  line-height: 1.8;
}

/* Contact Section */
.contact {
  background-color: #2c3e50; /* Dark bluish-gray */
  padding: 50px 20px;
  color: white;
  justify-items: center;
}
.logoInvert{
  width: 30px;
  height: 30px;
}
.contact-details ul {
  list-style: none;
  padding: 0;
}

.contact-details li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.contact-details a {
  font-size: 1.2em;
  margin-left: 10px;
}

/* Footer */
.footer {
  background-color: #212121; /* Deep charcoal */
  color: white;
  text-align: center;
  padding: 20px;
}

/* Map Section */
.myMap {
  background-color: #263238; /* Darker blue-gray */
  padding: 50px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.myMap-content {
  display: flex;
  width: 90%;
  max-width: 100%;
  text-align: left;
  flex-direction: row-reverse;
  justify-content: space-around;
}

.myMap h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ffffff;
}

.myMap-text {
  margin-bottom: 20px;
  color: #e0e0e0;
    text-align-last: right;
}

/* Map Component Styles */
.myMap iframe, .myMap .leaflet-container {
  width: 33% !important;
  height: 400px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive Map Styles */
@media (max-width: 768px) {
  .myMap {
    flex-direction: column;
    text-align: center;
  }

  .warranty-content{
    flex-direction: column;
  }

  .myMap iframe, .myMap .leaflet-container {
    width: 100% !important;
    height: 50vh !important;
  }

  .myMap-content {
    width: 100%;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .myMap h2 {
    font-size: 1.5em;
    justify-self: end;
  }

  .feature-cards{
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .myMap h2 {
    font-size: 1.5em;
  }

  .myMap iframe, .myMap .leaflet-container {
    height: 250px;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 1em;
  }

  .warranty-text h2 {
    font-size: 2em;
    justify-self: end;
  }

  .warranty-text p {
    font-size: 1.1em;
text-align: end;  }

  /* Footer */
  .footer {
    font-size: 0.9em;
    padding: 10px;
  }
}

/* Navbar Styles */
.navbar {
  background-color: rgba(9, 27, 36, 0.9);
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: none;
  padding: 15px 20px;
}

.navbar-brand img {
  width: 120px;
  max-width: 100%;
  height: auto;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-item {
  margin-right: 20px;
}

.nav-item:last-child {
  margin-right: 0;
}

.nav-link {
  font-size: 1.1em;
  color: #ffffff !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.nav-link:hover {
  text-decoration: underline;
}

.navbar-collapse {
  justify-content: flex-end;
}

/* Navbar Responsive Styles */
@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .nav-item {
    margin-bottom: 10px;
  }
}


/* About Us Section */
.about-us {
  padding: 60px 20px;
  background-color: #2a323c; /* Dark teal */
  color: white;
  text-align: right;
}

.about-us h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

.about-content {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; /* Wrap for responsiveness */
}

.about-text {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about-section {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.about-section h3 {
  font-size: 1.8em;
  color: #a1c6db;
  margin-bottom: 10px;
}

.about-section p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #e0e0e0;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }

  .about-us h2 {
    font-size: 2em;
  }

  .about-image {
    order: -1; /* Move image above text */
    margin-bottom: 20px;
  }
}


.features, .warranty, .contact, .myMap, .about-us {
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

li{
  list-style: none;
}